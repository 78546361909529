.contact-modal {
  display: flex;
  justify-content: center;
  align-items: center;
}

.contact-modal-content {
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  width: 40vw;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border: 2px solid #4CAF50;
  /* Borde verde */
}

.contact-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border: 1px solid #ddd;
  margin-bottom: 10px;
  border-radius: 5px;
  background-color: #f9f9f9;
}

.contact-info {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.action-icon {
  margin-left: 10px;
  color: #4CAF50;
  font-size: 20px;
  cursor: pointer;
}

.star-icon {
  color: gold;
  font-size: 20px;
  cursor: pointer;
}

.cancel-button {
  background-color: #f44336;
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
}

.no-contacts {
  text-align: center;
}

.no-contacts img {
  width: 400px;
  margin: 20px auto;
}

.add-contact-button,
.save-button,
.cancel-button {
  background-color: #4caf50;
  color: white;
  border: none;
  padding: 10px;
  margin-top: 10px;
  border-radius: 5px;
  cursor: pointer;
}

.add-contact-button {
  background-color: #0056b3;
}

.contact-form input {
  display: block;
  width: 100%;
  padding: 8px;
  margin: 5px 0;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.icon-button {
  background: none;
  border: none;
  cursor: pointer;
}

.edit-icon {
  color: #4CAF50;
}

.delete-icon {
  color: #f44336;
}

.add-contact-button {
  background-color: #0056b3;
  color: white;
  border: none;
  padding: 10px;
  margin-top: 10px;
  border-radius: 5px;
  cursor: pointer;
}

.contact-form input {
  display: block;
  width: 100%;
  padding: 8px;
  margin: 5px 0;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.primary-icon {
  color: gray; /* Estrella no seleccionada */
  font-size: 20px;
  cursor: pointer;
}

.primary-icon.filled {
  color: gold; /* Estrella seleccionada */
}